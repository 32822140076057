<template>
  <span class="image">
    <picture v-if="!product.rotate_for_insert">
      <img
        v-lazy-load :data-src="product.default_bottle_image_url"
        alt="Wine Bottle"
        height="220"
      />
    </picture>
    <picture v-else>
      <source v-lazy-load :data-srcset="product.bottle_image_optimized_url" type="image/webp" />
      <source v-lazy-load :data-srcset="product.bottle_image_url" type="image/jpeg" />
      <img v-lazy-load :data-src="product.bottle_image_url" alt="Wine Bottle" height="220" />
    </picture>
  </span>
</template>

<script>
export default {
  name: 'NarrativeProductCardImage',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
}
</script>
